/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';

const Privacy = ({ location }) => (
  <Layout location={location}>
    <div className="conditions-page" role="main">
      <SectionContainer num={1} container>
        <h2 style={{ color: '#cf1a9c', marginTop: '20px' }}>
          <FormattedMessage id="privacy.header" />
        </h2>
        <div className="content-block">
          <h3><FormattedMessage id='privacy.lastUpdated'/></h3>
          <p>
            <FormattedMessage id='privacy.weUsOur'/>
          </p>
          <p><FormattedMessage id='privacy.theInkblotWebsite'/></p>
          <ul>
            <li><FormattedMessage id='privacy.onDemandAccess'/></li>
            <li><FormattedMessage id='privacy.supportServices'/></li>
          </ul>
          <p><FormattedMessage id='privacy.onceAUser'/></p>
          <h2 className='section-header'><FormattedMessage id='privacy.scope'/></h2>
          <p><FormattedMessage id='privacy.thisPolicyApplies'/></p>
          <ul>
            <li><FormattedMessage id='privacy.howWeCollect'/></li>
            <li><FormattedMessage id='privacy.theTypesOfInformation'/></li>
            <li><FormattedHTMLMessage id='privacy.ourPracticesForCollecting'/></li>
          </ul>
          <p><FormattedMessage id='privacy.weWillOnlyUse'/></p>
          <p><FormattedMessage id='privacy.inThisPrivacyPolicy'/></p>
          <h2 className='section-header'>
            <FormattedMessage id='privacy.privacyPrinciples'/>
          </h2>
          <p><FormattedMessage id='privacy.theMethods'/></p>
          <p><FormattedMessage id='privacy.inkblotAlsoComplies'/></p>
          <p><FormattedMessage id='privacy.inkblotFollowsTen'/></p>
          <h5><FormattedMessage id='privacy.1Accountability'/></h5>
          <p><FormattedMessage id='privacy.weAreResponsible'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutAccountability'/></h4>
          <p><FormattedMessage id='privacy.allInkblotEmployees'/></p>
          <h5><FormattedMessage id='privacy.2IdentifyingPurposes'/></h5>
          <p><FormattedMessage id='privacy.weWillClearly'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutIdentifying'/></h4>
          <p><FormattedMessage id='privacy.weAskForYour'/></p>
          <ul>
            <li><FormattedMessage id='privacy.toProvideAnd'/></li>
            <li><FormattedMessage id='privacy.toCreateAnd'/></li>
            <li><FormattedMessage id='privacy.toOperateMaintainSupervise'/></li>
            <li><FormattedMessage id='privacy.toAttendTo'/></li>
            <li><FormattedMessage id='privacy.toConfirmYourIdentity'/></li>
            <li><FormattedMessage id='privacy.toRetainAppropriateRecords'/></li>
            <li><FormattedMessage id='privacy.toContactYouByEmail'/></li>
            <li><FormattedMessage id='privacy.toPromoteAnd'/></li>
            <li><FormattedMessage id='privacy.toConductResearch'/></li>
            <li><FormattedMessage id='privacy.toAnonymizeThe'/></li>
            <li><FormattedMessage id='privacy.toCombineInkblot'/></li>
            <li><FormattedMessage id='privacy.toFacilitateSharing'/></li>
          </ul>
          <p><FormattedMessage id='privacy.weShareYourPersonalInformation'/></p>
          <p><FormattedMessage id='privacy.weMayAlsoShareNon'/></p>
          <p><FormattedMessage id='privacy.whereAppropriate'/></p>
          <p><FormattedMessage id='privacy.wherePermittedOr'/></p>
          <h5><FormattedMessage id='privacy.3Consent'/></h5>
          <p><FormattedMessage id='privacy.weNeedYourConsent'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutConsent'/></h4>
          <p><FormattedMessage id='privacy.yourConsentCanBe'/></p>
          <p><FormattedMessage id='privacy.consentCanBeImplied'/></p>
          <p><FormattedMessage id='privacy.weWillContinueToUse'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutLimitedCircumstances'/></h4>
          <p><FormattedMessage id='privacy.weMayCollectUseOrDisclose'/></p>
          <ul>
            <li><FormattedMessage id='privacy.withGreenShield'/></li>
            <li><FormattedMessage id='privacy.withOurServiceProviders'/></li>
            <li><FormattedMessage id='privacy.withThirdParties'/></li>
            <ul>
              <li><FormattedMessage id='privacy.forEmergenciesThat'/></li>
              <li><FormattedMessage id='privacy.inConnectionWithSale'/></li>
              <li><FormattedMessage id='privacy.forLegalReasons'/></li>
              <li><FormattedMessage id='privacy.forThePurposesOf'/></li>
              <li><FormattedMessage id='privacy.forThePurposesOfDetecting'/></li>
            </ul>
          </ul>
          <h4><FormattedMessage id='privacy.learnMoreAboutWithdrawingConsent'/></h4>
          <p><FormattedMessage id='privacy.youMayWithdraw'/></p>
          <h5><FormattedMessage id='privacy.4LimitingCollection'/></h5>
          <p><FormattedMessage id='privacy.weWillLimit'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutThe'/></h4>
          <p><FormattedMessage id='privacy.dependingOnTheProduct'/></p>
          <ul>
            <li><FormattedMessage id='privacy.firstLastName'/></li>
            <li><FormattedMessage id='privacy.addressPostalCode'/></li>
            <li><FormattedMessage id='privacy.paymentInformationIncluding'/></li>
            <li><FormattedMessage id='privacy.medicalOrHealth'/></li>
            <li><FormattedMessage id='privacy.interactionAndRelationship'/></li>
            <li><FormattedMessage id='privacy.electronicDigitalInformation'/></li>
            <li><FormattedMessage id='privacy.yourAccountAnd'/></li>
            <li><FormattedMessage id='privacy.howYouUse'/></li>
          </ul>
          <h4><FormattedMessage id='privacy.LearnMoreAboutWhereWeCollect'/></h4>
          <p><FormattedMessage id='privacy.weMayCollectOrRecieve'/></p>
          <ul>
            <li><FormattedMessage id='privacy.completedApplications'/></li>
            <li><FormattedMessage id='privacy.yourInteractionsWithUs'/></li>
            <li><FormattedMessage id='privacy.yourTelehealthConsultations'/></li>
            <li><FormattedMessage id='privacy.updatesToYourOnline'/></li>
            <li><FormattedMessage id='privacy.yourMedicalRecords'/></li>
            <li><FormattedMessage id='privacy.yourDoctorPharmacist'/></li>
            <li><FormattedMessage id='privacy.medicalFacilities'/></li>
            <li><FormattedMessage id='privacy.professionalRegulatory'/></li>
          </ul>
          <h5><FormattedMessage id='privacy.5LimitingUse'/></h5>
          <p><FormattedMessage id='privacy.weWillOnlyUseOr'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutPersonalInformation'/></h4>
          <p><FormattedMessage id='privacy.dependingOnTheProductOrService'/></p>
          <ul>
            <li><FormattedMessage id='privacy.peopleHealthcareProviders'/></li>
            <li><FormattedMessage id='privacy.authorizedEmployees'/></li>
            <li><FormattedMessage id='privacy.yourCounsellorAndAny'/></li>
            <li><FormattedMessage id='privacy.anyPersonOrThird'/></li>
            <li><FormattedMessage id='privacy.peopleWhoAreLegally'/></li>
            <li><FormattedMessage id='privacy.otherEntities'/></li>
            <li><FormattedMessage id='privacy.serviceProvidersWhoNeedThis'/></li>
            <li><FormattedMessage id='privacy.peopleOrganizationsandInvestigative'/></li>
          </ul>
          <p><FormattedMessage id='privacy.whenWeDisclose'/></p>
          <h4><FormattedMessage id='privacy.learnMoreAboutHowLong'/></h4>
          <p><FormattedMessage id='privacy.weKeepYourPersonal'/></p>
          <h5><FormattedMessage id='privacy.6Accuracy'/></h5>
          <p><FormattedMessage id='privacy.weWillEndeavor'/></p>
          <h4><FormattedMessage id='privacy.learnAboutAccuracy'/></h4>
          <p><FormattedHTMLMessage id='privacy.youCanCheckYour'/></p>
          <p><FormattedMessage id='privacy.weWillAssistYou'/></p>
          <h5><FormattedMessage id='privacy.7Safeguards'/></h5>
          <p><FormattedMessage id='privacy.toProtectYourPersonal'/></p>
          <h4><FormattedMessage id='privacy.LearnMoreAboutSafeguards'/></h4>
          <p><FormattedMessage id='privacy.ourEmployeesWhoHaveAccess'/></p>
          <p><FormattedMessage id='privacy.yourPersonalInformation'/></p>
          <p><FormattedMessage id='privacy.althoughTheSharing'/></p>
          <h5><FormattedMessage id='privacy.8Openness'/></h5>
          <p><FormattedMessage id='privacy.weWillMakeInformation'/></p>
          <h5><FormattedMessage id='privacy.9IndividualAccess'/></h5>
          <p><FormattedMessage id='privacy.ifYouSendUs'/></p>
          <p><FormattedMessage id='privacy.pleaseNoteThatWe'/></p>
          <ul>
            <li><FormattedMessage id='privacy.isProhibitively'/></li>
            <li><FormattedMessage id='privacy.containsReferences'/></li>
            <li><FormattedMessage id='privacy.cannotBeDisclosedForLegal'/></li>
            <li><FormattedMessage id='privacy.isSubjectTo'/></li>
            <li><FormattedMessage id='privacy.cannotBeDisclosed'/></li>
          </ul>
          <h5><FormattedMessage id='privacy.10Questions'/></h5>
          <p><FormattedMessage id='privacy.weAreCommitted'/></p>
          <h5><FormattedMessage id='privacy.pleaseFeelFree'/></h5>
          <ul>
            <li><FormattedHTMLMessage id='privacy.byEmail'/></li>
            <li><FormattedMessage id='privacy.byMail'/></li>
            <p style={{marginLeft: '40px'}}>
              <FormattedHTMLMessage id='privacy.greenShieldAddress'/>
            </p>
          </ul>
          <p><FormattedMessage id='privacy.theInkblotPrivacyOffice'/></p>
          <h5><FormattedMessage id='privacy.IfYourConcernRemains'/></h5>
          <ul>
            <li><FormattedMessage id='privacy.officeOfPrivacyCommissioner'/></li>
            <p style={{marginLeft: '40px'}}>
              <FormattedHTMLMessage id='privacy.commisionerAddress'/>
            </p>
            <li><FormattedMessage id='privacy.telephone'/></li>
            <li><FormattedMessage id='privacy.tollFree'/></li>
            <li><FormattedMessage id='privacy.fax'/></li>
            <li><FormattedHTMLMessage id='privacy.website'/></li>
          </ul>
          <p><FormattedHTMLMessage id='privacy.youMayAlsoContact'/></p>
          <ul>
            <li><FormattedMessage id='privacy.informationAndPrivacyCommissioner'/></li>
            <p style={{marginLeft: '40px'}}>
              <FormattedHTMLMessage id='privacy.ontarioCommissionerAddress'/>
            </p>
            <li><FormattedMessage id='privacy.ontarioTelephone'/></li>
            <li><FormattedMessage id='privacy.ontarioToll'/></li>
            <li><FormattedHTMLMessage id='privacy.ontarioWebsite'/></li>
          </ul>
          <h2 className='section-header'>
            <FormattedMessage id='privacy.changesToThisPolicy'/>
          </h2>
          <FormattedHTMLMessage id='privacy.weMayFromTimeToTime'/>
          <h2 className='section-header'>
            <FormattedMessage id='privacy.cookiesAndMobileServices'/>
          </h2>
          <FormattedMessage id='privacy.ourServicesUseATechnology'/>
          <h2 className='section-header'>
            <FormattedMessage id='privacy.minorsPrivacy'/>
          </h2>
          <FormattedMessage id='privacy.thisWebsiteIsNotIntended'/>
          <h2 className='section-header'>
            <FormattedMessage id='privacy.crossBorderTransfers'/>
          </h2>
          <FormattedMessage id='privacy.yourPersonalInformationMayBe'/>
          <h2 className='section-header'>
            <FormattedMessage id='privacy.yourRights'/>
          </h2>
          <p><FormattedMessage id='privacy.youCanContactUsDirectly'/></p>
          <h5><FormattedMessage id='privacy.1Access'/></h5>
          <p><FormattedMessage id='privacy.subjectToApplicableLaw'/></p>
          <h5><FormattedMessage id='privacy.2amendmentOrDeletion'/></h5>
          <p><FormattedMessage id='privacy.subjectToApplicableLawAmendment'/></p>
          <h5><FormattedMessage id='privacy.3DataProcessing'/></h5>
          <p><FormattedMessage id='privacy.subjectToApplicableLawObjection'/></p>
          <h5><FormattedMessage id='privacy.4AutomatedDecisionMaking'/></h5>
          <p><FormattedMessage id='privacy.subjectToApplicableLawIf'/></p>
          <h5><FormattedMessage id='privacy.5WithdrawYourConsent'/></h5>
          <p><FormattedMessage id='privacy.subjectToApplicableConsent'/></p>
          <p><FormattedMessage id='privacy.pleaseNoteThatTheseRights'/></p>
          <p><FormattedMessage id='privacy.inkblotShallProvide'/></p>
          <h5><FormattedMessage id='privacy.6DataPortability'/></h5>
          <p><FormattedMessage id='privacy.youHaveTheRightToReceive'/></p>
        </div>
      </SectionContainer>
    </div>
  </Layout>
);

Privacy.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Privacy;
